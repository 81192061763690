<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Limits</a></li>
    </ol>
    <h1 class="page-header">BIDBOND LIMITS</h1>
    <panel noButton="true" title="MANAGE BIDBOND LIMITS" variant="panel-default">
      <form @submit.prevent="save">
        <div class="row">
          <div class="form-group col-md-6">
            <label>System Bank Limit</label>
            <vue-numeric
                v-model="setting.bank_limit"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Company Limit</label>
            <vue-numeric
                v-model="setting.company_limit"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label>MPF</label>
            <input
                v-model="setting.mpf"
                class="form-control"
                max="1"
                min="0"
                step="0.01"
                type="number"
            >
          </div>
          <div class="form-group col-md-6">
            <label>Other</label>
            <input
                v-model="setting.other"
                class="form-control"
                max="1"
                min="0"
                step="0.01"
                type="number"
            >
          </div>
          <div class="form-group col-md-12">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>
export default {

  data() {
    return {
      settings: [],
      setting: {
        bank_limit: '',
        indemnity_cost: '',
        company_limit: '',
        excise_duty: '',
        mpf: '',
        nic: '',
      },
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/settings').then(response => {
        this.setting = response.data;
        this.loading = false;
      });
    },

    save() {
      this.$axios.post('/api/v1/settings', this.setting).then(() => {
        this.$toastr.s('The settings have been updated', 'Saved');
      });
    },
  }
}
</script>
